import request from '@/utils/request'

export function getOrgList(params) {
    return request({
        url: '/dept/page',
        method: 'GET',
        params,
    })
}

export function getOrgTree(data) {
    return request({
        url: '/dept/tree',
        method: 'POST',
        data,
    })
}

export function createDept(data) {
    return request({
        url: data.id ? `/dept/update/${data.id}` : `/dept/create`,
        method: 'POST',
        data,
    })
}
export function deleteDept(data) {
    return request({
        url: '/dept/delete',
        method: 'POST',
        data,
    })
}





