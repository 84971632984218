<template>
  <div class="full-card has-table">
    <a-row :gutter="20">
      <a-col :span="5">
        <a-card>
          <a-tree
              :replaceFields="replaceFields"
              :tree-data="treeData"
              :defaultExpandAll="true"
              v-if="treeData && treeData.length"
          >
          </a-tree>
        </a-card>
      </a-col>
      <a-col :span="19">
        <div class="handle-area">
          <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addDept']">新增部门</a-button>
        </div>
        <a-card>
          <a-table
              :columns="columns"
              :data-source="list"
              :loading="tableLoading"
              :pagination="pagination"
              rowKey="id"
              bordered
              @change="pageChange"
              :scroll="{ y: 'calc(100% - 55px)'}"
          >
            <template slot="id" slot-scope="text, record">
              <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editDept']">
              <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteDept']">
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}部门` "
        placement="right"
        :visible="createShow"
        @close="createShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="部门名称" prop="deptName">
              <a-input placeholder="请输入部门名称" v-model="form.deptName" />
            </a-form-model-item>
            <a-form-model-item label="上级部门" prop="parentId">
              <a-tree-select
                  v-model="form.parentId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="treeData"
                  :replaceFields="replaceFields"
                  placeholder="请选择上级部门"
                  tree-default-expand-all
              >
              </a-tree-select>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getOrgList, getOrgTree, createDept, deleteDept } from '@/api/humanResources/organization'
export default {
  name: 'organization',
  data () {
    return {
      columns: [
        {
          title: '部门名称',
          dataIndex: 'deptName'
        },
        {
          title: '上级部门',
          dataIndex: 'parentDeptName'
        },
        {
          title: '本部门关联岗位',
          dataIndex: 'postName'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        }
      ],
      list: [],
      createShow: false,
      filterShow: false,
      filterForm: {},
      form: {},
      page: 1,
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      treeData: [],
      replaceFields: {
        title: 'deptName',
        key: 'id',
        value: 'id'
      },
      rules: {
        deptName: [
          { required: true, message: '请输入部门名称', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.getList()
    this.getTree()
  },
  methods: {
    onSearch () {

    },
    getList () {
      getOrgList({
        pageNum: this.page,
        pageSize: 10
      }).then(res => {
        this.list = res.data.list
        this.pagination.total = res.data.total
        this.tableLoading = false
      })
    },
    getTree () {
      getOrgTree().then(res => {
        this.treeData = res.data
      })
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteDept({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onReset () {},
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          createDept(this.form).then(() => {
            this.$message.success('创建成功！')
            this.getList()
            this.getTree()
            this.createShow = false
          })
        }
      });
    },
    pageChange (pages) {
      this.page = pages.current
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
